* {
  color: white;
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: #110f36;
}

.currentVideoTitle {
  padding-left: 5px;
  padding-bottom: 5px;
  color: white;
  display: flex;
  flex: 5;
}

.playerWrapper {
  position: relative !important;
  padding-top: 56.25% !important;
}

.player {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important
}

.contentRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.sytButton {
  border: 1px solid gray;
  font-size: 18px;
  color: black;
  height: 40px;
  padding: 4px 20px;
  margin: 4px 0px;
  cursor: pointer;
  width: 100%;
  display: block;
}

.sytButton:focus {
  outline: none;
}

.sytButton.toggled {
  background-color: rgb(50, 184, 141);
}

.buttonList {
  border: 1px solid white;
  padding: 4px;
  margin: 2px;
}

.nextVideoButton {
  height: 6em;
}

.chevron-down {
  transform: rotate(0deg);
}

.chevron-down.flip {
  transform: rotate(180deg);
}

.playlistSelector {
  color: white;
  border: 1px solid white;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 18px;
  margin: 2px;
}

.playlistSelectorTitle {
  text-align: center;
  font-size: 18px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 2px;
}

.playlistSelectorInner {
  color: white;
  padding: 4px;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid white;

  overflow: auto;
  max-height: 16em;
}

.playlistSelectorItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px 3px;
}

.checkbox_label {
  display: flex;
  gap: 10px;
}

.checkbox_input {
  width: 25px;
  height: 25px;
  padding: 0;
  top: -1px;
  display: flex;
  flex-shrink: 0;
}

.videoPool {
  border: 1px solid white;
  padding: 4px;
  margin: 2px;
  flex: 1;
  max-height: 20em;
  display: flex;
  flex-direction: column;
}

.videoPoolTitle {
  color: white;
  text-align: center;
  font-size: 18px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 2px;
}

.videoPoolList {
  display: flex;
  flex-direction: column;
  border-top: 1px solid white;
  overflow-y: auto;
}

.videoPoolItem {
  padding: 1px 3px;
  font-size: 18px;
  color: white;
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid white;
}

.videoPoolItem:hover {
  background-color: rgb(2, 85, 2);
}

.videoPoolItem.selected {
  background-color: rgb(2, 78, 2);
}

.hide {
  display: none !important;
}

.link {
  color: inherit;
  text-decoration: inherit;
}

.trackPlaylistForm {
  display: grid;
  grid-template-columns: 100px 500px;
  grid-gap: 10px;
}

.trackPlaylistFormTitle {
  font-size: 40px;
  border-bottom: 1px solid white;
}

.trackPlaylistLabel {
  font-size: 28px;
  margin-right: px;
  vertical-align: b;
}

.trackPlaylistInput {
  width: 500px;
}

.trackPlaylistFormRow {
  margin: 4px;

}

input {
  background-color: #dad8df;
  border: 1px solid gray;
  color: black;
  padding: 10px 10px;
  margin: 0px 0px;
  cursor: pointer;
  box-sizing: border-box;
}

.loader {
  height: 100%;
  width: 50%;
  text-align: center;
  padding: 1em;
  display: table;
  margin: 0 auto;
  vertical-align: top;
}

svg path,
svg rect {
  fill: #FF6700;
}

.pageTitle {
  font-size: 44px;
  text-align: center;
  padding: 1px;
}

.searchField {
  width: 100%;
  box-sizing: border-box;
}

.searchFieldContainer {
  padding: 4px;
}

.shaFooter {
  text-align: center;
  color: #404040;
  padding: 5px;
}

.shaFooter a {
  color: inherit;
}